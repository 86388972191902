import React from 'react';
import { Button, Tooltip } from 'antd';

import {useListDangers, useListSourceDanger,useListWorkPlace, useListWorkType} from './hooks';
//import * as Styled from './styled';

export function WorkPlace(props)
{

const list = useListWorkPlace(props.workplace).listWorkPlace;
const formik = props.formik;
if(formik.status != 'addDanger'){
  return null;
}else if(formik.values.isCommonHazards){
  return null;
}

  return ( <div style={{'margin-top': '-20px', 'margin-bottom': '20px' }}>
    {list.map((item) => (

      <Tooltip title={item.name} placement="top">
          <Button size="small" style={{width: '130px', margin: '2px'}} onClick={()=>{

            props.formik.setFieldValue('data.work_place', {id: item.work_place_id, name: item.name});

          }}>
            <span style={{width: '120px', 'font-size': '10px',  'overflow': 'hidden', 'text-overflow': 'ellipsis'}}>{item.name}</span>
          </Button>
      </Tooltip>

    ))}
  </div>);
}

export function WorkType(props)
{

const list = useListWorkType(props.workplace).listWorkType;
const formik = props.formik;
if(formik.status != 'addDanger'){
  return null;
}else if(formik.values.isCommonHazards){
  return null;
}

  return ( <div style={{'margin-top': '-20px', 'margin-bottom': '20px' }}>
    {list.map((item) => (

      <Tooltip title={item.name} placement="top">
          <Button size="small" style={{width: '130px', margin: '2px'}} onClick={()=>{

            props.formik.setFieldValue('data.work_type', {id: item.work_type_id, name: item.name});

          }}>
            <span style={{width: '120px', 'font-size': '10px',  'overflow': 'hidden', 'text-overflow': 'ellipsis'}}>{item.name}</span>
          </Button>
      </Tooltip>

    ))}
  </div>);
}

export function SourceDanger1(props)
{

const list = useListSourceDanger(props.workplace).listSourceDanger;
const formik = props.formik;
if(formik.status != 'addDanger'){
  return null;
}else if(formik.values.isCommonHazards){
  return null;
}

  return ( <div style={{'margin-top': '-20px', 'margin-bottom': '20px' }}>
    {list.map((item) => (

      <Tooltip title={item.name} placement="top">
          <Button size="small" style={{width: '130px', margin: '2px'}} onClick={()=>{

            props.formik.setFieldValue('data.source_danger', {id: item.source_danger_id, name: item.name});

          }}>
            <span style={{width: '120px', 'font-size': '10px',  'overflow': 'hidden', 'text-overflow': 'ellipsis'}}>{item.name}</span>
          </Button>
      </Tooltip>

    ))}

  </div>);
}

export function Dangers(props)
{

const list = useListDangers(props.workplace).listDangers;
const formik = props.formik;
if(formik.status != 'addDanger'){
  return null;
}else if(formik.values.isCommonHazards){
  return null;
}
//console.log(list);
  return ( <div style={{'margin-top': '-20px', 'margin-bottom': '20px' }}>
    {list.map((item) => (

      <Tooltip title={item.name} placement="top">
          <Button size="small" style={{width: '130px', margin: '2px', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} onClick={()=>{

            props.formik.setFieldValue('data.danger', {id: item.danger_id, name: item.name});

          }}>
            <span style={{width: '120px', 'font-size': '10px',  'overflow': 'hidden', 'text-overflow': 'ellipsis'}}>{item.name}</span>
          </Button>
      </Tooltip>

    ))}
    <br />
  </div>);
}

