import { toast } from 'react-toastify';

import axios from '../../../../axios/axios';
import { logWarn } from '../../../../utils/log';

export function usePostAddhazard({
  formik,
  workplace,
  onPostProjectHazard,
  onPostProjectEvaluationcardHazard,
  onShowAddDanger,
  project,
}) {

  const onPostAddhazard = async () => {
    const data = formik?.values?.data;

    try {
      const { data: responseData } = await axios.post(`/api/project/riskassessment/evaluationcard/addhazard`, {
        workplace_id: workplace,
        work_type_id: data.work_type?.id,
        work_place_id: data.work_place?.id,
        source_danger_id: data.source_danger?.id,
        danger_id: data.danger?.id,
        measures: data.measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
        custom_measures: data.custom_measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
      });

      if (responseData?.status === 'success') {
        toast['success'](responseData?.msg);
        onPostProjectHazard?.();
        onPostProjectEvaluationcardHazard?.();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    } finally {
      onShowAddDanger.off();
      formik.resetForm();
    }
  };

  const onPostSavehazard = async (hazard_id) => {
    const data = formik?.values?.data;
    try {
      const { data: responseData } = await axios.post(`/api/project/riskassessment/evaluationcard/savehazard`, {
        hazard_id,
        work_type_id: data.work_type?.id,
        work_place_id: data.work_place?.id,
        source_danger_id: data.source_danger?.id,
        danger_id: data.danger?.id,
        measures: data.measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
        custom_measures: data.custom_measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
      });

      if (responseData?.status === 'success') {
        toast['success'](responseData?.msg);
        onPostProjectHazard?.();
        onPostProjectEvaluationcardHazard?.();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    } finally {
      onShowAddDanger.off();
      formik.resetForm();
    }
  };

  const onPostDeleteSettlement = async ({ hazard_id, measure_id, currentIndex, nameField }) => {
    const { value } = formik?.getFieldProps(nameField) ?? {};

    const deleteFied = value?.filter((_, index) => {
      return currentIndex !== index;
    });

    if (!measure_id) {
      return formik?.setFieldValue(nameField, deleteFied);
    }

    try {
      const { data } = await axios.post(`/api/project/riskassessment/evaluationcard/deletesettlement`, {
        hazard_id,
        measure_id,
      });

      if (data?.status === 'success') {
        toast['success'](data?.msg);
        formik?.setFieldValue(nameField, deleteFied);
        onPostProjectHazard?.();
        onPostProjectEvaluationcardHazard?.();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  const onPostSaveProjecthazard = async (hazard_id) => {
    const data = formik?.values?.data;

    try {
      const { data: responseData } = await axios.post(`/api/project/riskassessment/evaluationcard/saveprojecthazard`, {
        hazard_id,
        project_id: project,
        source_danger_id: data.source_danger?.id,
        danger_id: data.danger?.id,
        measures: data.measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
        custom_measures: data.custom_measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
      });

      if (responseData?.status === 'success') {
        toast['success'](responseData?.msg);
        onPostProjectHazard?.();
        onPostProjectEvaluationcardHazard?.();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    } finally {
      onShowAddDanger.off();
      formik.resetForm();
    }
  };

  const onPostAddProjecthazard = async (hazard_id) => {
    const data = formik?.values?.data;

    try {
      const { data: responseData } = await axios.post(`/api/project/riskassessment/evaluationcard/addprojecthazard`, {
        hazard_id,
        project_id: project,
        source_danger_id: data.source_danger?.id,
        danger_id: data.danger?.id,
        measures: data.measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
        custom_measures: data.custom_measures
          ?.map((el) => ({
            id: el.id,
            visible: el.visible ?? false,
          }))
          .filter((el) => Boolean(el.id)),
      });

      if (responseData?.status === 'success') {
        toast['success'](responseData?.msg);
        onPostProjectHazard?.();
        onPostProjectEvaluationcardHazard?.();
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    } finally {
      onShowAddDanger.off();
      formik.resetForm();
    }
  };

  const onPostReferenceBundle = async ({
    hazardId,
    workplaceId,
    projectId,
    sourceDangerId,
    dangerId,
    isCollective,
  }) => {
    try {
      const { data } = await axios.post(`/api/reference/bundle`, {
        hazard_id: hazardId,
        project_id: projectId,
        workplace_id: workplaceId,
        source_danger_id: sourceDangerId,
        danger_id: dangerId,
        is_collective: isCollective,
      });

      if (data?.status === 'success') {
        formik?.setFieldValue('data.measures', data.data);
      }
    } catch (e) {
      console.log(e);

      toast['error'](e.data?.msg);

      logWarn({
        errorNumber: '1685084102343',
        errorMessage: `${e}`,
      });
    }
  };

  return {
    onPostAddhazard,
    onPostSavehazard,
    onPostDeleteSettlement,
    onPostSaveProjecthazard,
    onPostAddProjecthazard,
    onPostReferenceBundle,
  };
}
