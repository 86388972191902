import styled from 'styled-components';

import Flex from '../../../atoms/flex';
import Button from '../../../atoms/nButton';
import Text from '../../../atoms/text';

export const FillButton = styled(Button)`
  margin: 0 0 27px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  padding: 9px 10px;
`;

export const FlexListOfReportsTop = styled(Flex)`
  flex-flow: nowrap;
  justify-content: flex-end;
`

export const ListOfReportsTypeFile = styled.div`
  font-family: GolosTextWebVF, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 10px;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: ${props => props.background ?? 'transparent'};
`

export const FlexListOfReportsBody = styled.div`
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #B5B9C1;
`

export const FlexListOfReportsBodyRow = styled(Flex)`
  flex-wrap: nowrap;
  align-items: center;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #B5B9C1;
`

export const FlexListOfReportslabel = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #000;
  display: block;
  padding: 7px 8px;
`;

export const FlexListOfReportsRadio = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 0;
  border-left: 1px solid #B5B9C1;
`;

export const FlexListOfReportsCircle = styled.div`
  width: 60px;
  height: 20px;
  margin: auto;
  text-align: center;

  .ant-checkbox-wrapper {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`

export const Buttons = styled(Flex)`
  margin: 15px 0 0;
  font-size: 14px;
`;

export const ListOfReportsButton = styled(Button)`
  font-size: 14px;

  &:disabled,
  &[disabled=disabled] {
    background: #F6F6F7;
    border-color: #F6F6F7;
    color: #CCCCD3;
    cursor: default;
  }
`;
