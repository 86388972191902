import React from "react";
import { toast } from "react-toastify";
import { Modal } from 'antd';
//import { Form, Formik, useFormik} from 'formik';
//import Input from '../../../../../atoms/input';
import axios from "axios";

//import FieldInput from '../../../../../formik/fields/input'
import Button from '../../../../../atoms/nButton';

import * as Styled from './styled';

export default function NewModal({showModal, table, data, parent, onClose, del}){



function Save(){
  const form = document.getElementById('newForm');
  console.log(form)
  axios.post('/api/classifier/position/insert',
    {
      table: table,
      //id: data.id,
      parent: parent,
      code: form.Cod.value,
      name: form.Name.value,
      nameevent: form.NameEvent ?  form.NameEvent.value : '',
      codeevent: form.CodEvent ?  form.CodEvent.value : '',
      measure: form.measure ? form.measure.value : '',
    }
  )
  .then(function(response){

    if(response.data.code === 200){
      toast['success']('Позиция добавлена')

      del()
      onClose();
    }else if(response.data.code === 201){

      toast['error'](response.data.msg)
    }
    //setRight(response.data);
  })

}

  return (

      <Modal
      open= {showModal}
      title={"Добавить позиции"}
      //onOk={okSave}
      //modalRender={true}
      destroyOnClose={true}
      onCancel={onClose}
      width={800}
      height={300}
      footer={false}
      >
      <form id="newForm" preserve={false}>
        <table>
          <tr>
            <th width={'20%'} style={{textAlign: 'center', "padding-top": "10px"}}>
              Код
            </th>
            <th style={{textAlign: 'center', "padding-top": "10px"}}>
              { table === 'dangers' ? 'Опасность' :
                'Наименование позиции'}
                {console.log(table)}
            </th>
            { table === 'mitigation_measures' ?
            <th width={'20%'} style={{textAlign: 'center', "padding-top": "10px"}}>Вес меры</th> : ''}
          </tr>
          <tr >
            <td align="center" style={{"padding-top": "10px"}}>

              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    label={"Код"}
                    id={'Cod'}
                    name= {'Сod'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>



            </td>
            <td style={{"padding-top": "10px"}}>
              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Код"}
                    id={'Name'}
                    //defaultValue={data.name}
                    //formik={formik.values.Cod}

                    name= {'Name'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>

            </td>
            { table === 'mitigation_measures' ?
              <td align="center" style={{"padding-top": "10px"}}>
                <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    type={'number'}
                    //placeholder={'Вес меры'}
                    label={"Вес меры"}
                    id={'measure'}
                    //defaultValue={data.code}
                    //formik={formik.values.Cod}

                    name= {'measure'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>
              </td>
            : ""}
          </tr>
        </table>
        {  table === "dangers" ?
          <table>
          <tr>
            <th width={'20%'} style={{textAlign: 'center', "padding-top": "10px"}}>
              Код
            </th>
            <th style={{textAlign: 'center', "padding-top": "10px"}}>
              Опасное событие
            </th>
          </tr>
          <tr >
            <td align="center" style={{"padding-top": "10px"}}>

              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Код"}
                    id={'CodEvent'}
                    //defaultValue={data.code}
                    //formik={formik.values.Cod}

                    name= {'СodEvent'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>



            </td>
            <td style={{"padding-top": "10px"}}>
              <Styled.Field>
                <Styled.InputTextWrap>
                  <Styled.InputText
                    //margin={'0 0 0px'}
                    //placeholder={'Код'}
                    label={"Код"}
                    id={'NameEvent'}
                    //defaultValue={data.name}
                    //formik={formik.values.Cod}

                    name= {'NameEvent'}
                  />
                </Styled.InputTextWrap>
              </Styled.Field>

            </td>

          </tr>
        </table> : ""
        }
        <Styled.Buttons jContent={'right'}>
        <Button
              variant={'secondary'}
              onClick={Save}
              //disabled={!isDisabled}
            >
              Добавить
            </Button>
        </Styled.Buttons>
        </form>

      </Modal>

  )


}
