import React from 'react';
import { useSelector } from 'react-redux';
import {WarningOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useFormik } from 'formik';
import Cookies from 'universal-cookie';

import Copy from '../../../../assets/svg/copy';
import Printer from '../../../../assets/svg/printer';
import Message from '../../../../Components/message';
import * as hooks from '../../../../hooks';
import Portal from '../../../blocks/portal';
import EvaluationCard from '../../../blocks/riskAssessmentCard/components/evaluationCard';
import Spinner from '../../../blocks/spinner';
import AddDanger from '../addDanger';
import AdditionalControls from '../additionalControls';
import CopyMap from '../copyMap';
import SelectCard  from '../selectCard';

import * as Fields from './fields';
import { usePostProjectRiskassessment, useRiskAssessmentEvaluationCard } from './hooks';
import * as Styled from './styled';
import TableHazardAssessment from './tableHazardAssessment';

const { useBoolean } = hooks;


const initialValues = {
  data: {
    work_place: {
      id: null,
      name: null,
    },
    work_type: {
      id: null,
      name: null,
    },
    source_danger: {
      id: null,
      name: null,
    },
    danger: {
      id: null,
      name: null,
    },
    measures: [],
    custom_measures: [],
  },
};


export default function HazardAssessment(props) {
  const { project, workplace } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [showAddDanger, onShowAddDanger] = useBoolean(false);
  const [showCopyMap, onShowCopyMap] = useBoolean(false);
  const [showOk01694Professional, onShowOk01694Professional] = useBoolean(false);
  const [showSoutResults, onShowSoutResults] = useBoolean(false);
  const [showWorkplaceName, onShowWorkplaceName] = useBoolean(false);
  const [isRenderSpinner, onIsRenderSpinner] = useBoolean(false);
  const [showAdditionalControls, onShowAdditionalControls] = useBoolean(false);
  const [rowId, setRowId] = React.useState(null);
  //const [ticket, setTicket] = React.useState(null);
//console.log('hazard');
  const formikHazardAssessment = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [],
      editQuantity: {},
    },
  });

  const formikAddDanger = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
  });

  const formikHeaderTop = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: {},
    },
  });

  const additionalControlsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [
        {
          name: '',
        },
      ],
    },
  });
  let lis;
  const {
    loading,
    dataRows,
    onPostProjectHazard,
    onPostEvaluationcardEdithazard,
    onPostRiskassessmentOne,
    onPostEvaluationcardDelete,
    onEvaluationcardApproved,
    onPostEvaluationcardAdditionaledit,
    onAutomaticEvaluation,
    listDanger
  } = usePostProjectRiskassessment({
    project,
    workplace,
    formik: formikHazardAssessment,
    formikAddDanger: formikAddDanger,
    onShowAddDanger,
    formikHeaderTop,
    onShowAdditionalControls,
    additionalControlsFormik,
    onIsRenderSpinner,
    lis,
  });

  /* var hazardTickets = listDanger()

  hazardTickets.then((e) => {
    console.log(e);
  }) */
  const { formikEvaluationCard, onPostRiskAssessmentOne } = useRiskAssessmentEvaluationCard({ project, workplace });

  const token = localStorage.getItem('token');
  const printProjectPath = `/api/reports/report10?uid=${workplace}&bearer=${token}`;

  const handleAddDanger = () => {

    formikAddDanger.setStatus('addDanger');
    //formikAddDanger.resetForm();
    //formikAddDanger.setValues('work_place', {id: null, name: null});
    //console.log('addDanger',formikAddDanger.values.data);
    onShowAddDanger.on();
  };
  const [openCard, setOpenCard] = React.useState(false);

  function closeModal(){

    setOpenCard(false)
  }

  function okModal(e){
    console.log(e);
    setOpenCard(false);
    onConfirmAutomatic(e);

  }

  const handleAutomaticEvaluation = async (e) => {
    if (formikEvaluationCard.values?.data?.id) {
      onIsRenderSpinner.on();
      console.log(e);
      await onAutomaticEvaluation(formikEvaluationCard.values?.data.id, e);
    }
  };

  const CopyButton = ()  => {
    const cook = new Cookies();
    if(cook.get('Role') === 'start-role'){
      return (
        <Styled.ButtonIconSmall variant={'icon'}>
          <Copy />
          <Styled.ButtonIconSmalllabel>
          <Message
            buttonName = {{
              label: "Копировать карту"
            }}
            content1 = "Функция доступна для пользователей с ролью “Профессионал”."
            content2 = "За дополнительной информацией обратитесь к администаторам АИС “РискЛаб”."
            />
          </Styled.ButtonIconSmalllabel>
        </Styled.ButtonIconSmall>
      )
    }else{
      return (
            <Styled.ButtonIconSmall variant={'icon'} onClick={onShowCopyMap.on}>
              <Copy />
              <Styled.ButtonIconSmalllabel>Копировать карту</Styled.ButtonIconSmalllabel>
            </Styled.ButtonIconSmall>
      )
    }
  }

  const onConfirmAutomatic = (e) => {
    console.log(e);
      modal.confirm({
        title: '',
        className: 'confirm-automatic-evaluation',
        icon: <WarningOutlined style={{ fontSize: 40, color: '#FFB82E' }} />,
        onOk: () => handleAutomaticEvaluation(e),
        content:
          'При выполнении автоматической оценки все изменения внесенные в карты рабочих мест будут отменены.\n' +
          'Система предзаполнит карты на основе “Базы знаний АИС”.',
        okText: 'Подтвердить',
        cancelText: 'Отмена',
      });
  }
  const auth = useSelector((state) => state.authReducer);
  const isStartRole = auth.user.roles.some((user) => 'start-role' === user.roleName);
  const openSelectCard = () => {
    setOpenCard(true)
    //console.log(openCard);
  }

  return (
    <React.Fragment>
      <EvaluationCard formik={formikEvaluationCard} workplace={workplace} onPostRiskAssessmentOne={onPostRiskAssessmentOne} onRefetchProjectHazard={onPostProjectHazard} />
      <Styled.SectionHeadMiddle jContent={'flex-end'} wrap={'nowrap'} paddingItem={['0 15px 0 0']}>
          <Styled.ButtonConfirmAutomatic variant={'secondary'} onClick={openSelectCard}>{/* onClick={onConfirmAutomatic}> */}
            Выбрать карту
          </Styled.ButtonConfirmAutomatic>
        <Styled.ButtonAddDanger variant={'primary'} onClick={handleAddDanger}>
          Добавить опасность
        </Styled.ButtonAddDanger>
        <React.Fragment>
          <CopyButton />
          <Styled.LinkPrint href={printProjectPath} target="_blank" rel="noreferrer">
            <Printer />
            <Styled.ButtonIconSmalllabel>Печать карты</Styled.ButtonIconSmalllabel>
          </Styled.LinkPrint>
        </React.Fragment>
      </Styled.SectionHeadMiddle>
      <TableHazardAssessment
        loading={loading}
        data={dataRows}
        setRowId={setRowId}
        onShowAdditionalControls={onShowAdditionalControls}
        onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
        onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
        onPostEvaluationcardDelete={onPostEvaluationcardDelete}
      />
      <Styled.Buttons jContent={'flex-end'}>
        <Styled.ButtonNormal disabled={loading} variant={'primary'} onClick={onEvaluationcardApproved}>
          Утвердить рабочее место
        </Styled.ButtonNormal>
      </Styled.Buttons>
      <Portal id={'addDanger'}>
        <AddDanger
          rowId={rowId}
          project={project}
          workplace={workplace}
          formik={formikAddDanger}
          showAddDanger={showAddDanger}
          setRowId={setRowId}
          onPostProjectHazard={onPostProjectHazard}
          onShowAddDanger={onShowAddDanger}
          onPostEvaluationcardEdithazard={onPostEvaluationcardEdithazard}
        />
      </Portal>

      <AdditionalControls
        rowId={rowId}
        formik={additionalControlsFormik}
        showAdditionalControls={showAdditionalControls}
        onShowAdditionalControls={onShowAdditionalControls}
        onPostProjectHazard={onPostProjectHazard}
        onPostEvaluationcardAdditionaledit={onPostEvaluationcardAdditionaledit}
      />
      <Portal id={'showCopyMap'}>
        <CopyMap
          project={project}
          workplace={workplace}
          showCopyMap={showCopyMap}
          onShowCopyMap={onShowCopyMap}
          isRenderSpinner={isRenderSpinner}
          onIsRenderSpinner={onIsRenderSpinner}
        />
      </Portal>
      <Fields.Ok01694Profession
        formik={formikHeaderTop}
        workplace={workplace}
        show={showOk01694Professional}
        onPostRiskassessmentOne={onPostRiskassessmentOne}
        onShow={onShowOk01694Professional}
      />
      <Portal id={'soutResults'}>
        <Fields.SoutResults
          formik={formikHeaderTop}
          workplace={workplace}
          show={showSoutResults}
          onPostProjectHazard={onPostProjectHazard}
          onShow={onShowSoutResults}
        />
      </Portal>
      <Fields.WorkplaceName
        formik={formikHeaderTop}
        onPostRiskassessmentOne={onPostRiskassessmentOne}
        workplace={workplace}
        isRender={showWorkplaceName}
        onShow={onShowWorkplaceName}
      />
      {openCard &&
      <SelectCard
        openCard={openCard}
        closeCard={() => closeModal()}
        okCard={(e) => okModal(e)}
        data={formikEvaluationCard.values?.data.id}
      />}
      <Spinner isRender={isRenderSpinner} />
      {contextHolder}
    </React.Fragment>
  );
}
