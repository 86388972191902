import React from "react";
import axios from "axios";

export function useListWorkPlace (e)
{
  //var work = props.workplace;
  const [listWorkPlace, setListWorkPlace] = React.useState([]);
  //console.log(props.workplace);
  React.useEffect(() => {
  axios.post(
    '/api/list/work_place', {
      'workplace': e
    }).then(function(response){
        setListWorkPlace(response.data.data);
        //console.log('qq', e)
    })
  },[])

  return {listWorkPlace};
}

export function useListWorkType(e)
{
  const [listWorkType, setListWorkType] = React.useState([]);
  React.useEffect(() => {
    axios.post(
      '/api/list/work_type', {
        'workplace': e
      }).then(function(response){
          setListWorkType(response.data.data);
          //console.log('qq', e)
      })
    },[])

    return {listWorkType};
}

export function useListSourceDanger(e)
{
  const [listSourceDanger, setListSourceDanger] = React.useState([]);
  React.useEffect(() => {
    axios.post(
      '/api/list/source_danger', {
        'workplace': e
      }).then(function(response){
          setListSourceDanger(response.data.data);
          //console.log('qq', e)
      })
    },[])

    return {listSourceDanger};
}

export function useListDangers(e)
{
  const [listDangers, setListDangers] = React.useState([]);
  React.useEffect(() => {
    axios.post(
      '/api/list/dangers', {
        'workplace': e
      }).then(function(response){
          setListDangers(response.data.data);
          //console.log('qq', response.data.data)
      })
    },[])

    return {listDangers};
}
