import React, { useEffect } from 'react';
import { Table } from 'antd';

import { useBoolean } from '../../../../hooks';
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';
import RemoveButtonWithConfirm from '../../../removeButtonWithConfirm';
import AddDanger from '../addDanger';

import * as Fields from './fields';
import { usePostProjectEvaluationcardHazard } from './hooks';
import * as Styled from './styled';

export const RiskManagementMeasures = (props) => {
  const { isRender, onClose, project, workplace, onRefetchTable } = props;
  const [rowId, setRowId] = React.useState(null);
  const [showAddMeasures, onShowAddMeasures] = useBoolean(false);

  const {
    loading,
    dataRows,
    onPostMeasuresproject,
    onPostEvaluationcardAddMeasuresProject,
    onPostEvaluationcardDeleteMeasuresProject,
    formikAddDanger,
  } = usePostProjectEvaluationcardHazard({
    project,
    workplace,
    onShowAddMeasures,
  });

  const onRemoveItem = (measureId) => onPostEvaluationcardDeleteMeasuresProject(measureId);

  useEffect(() => {
    if (isRender) {
      onPostMeasuresproject();
    }
  }, [isRender]);

  const handleAddDanger = () => {
    formikAddDanger.setStatus('onlyMeasures');
    onShowAddMeasures.on();
    formikAddDanger.setFieldValue(
      `data.custom_measures.${formikAddDanger.values?.data?.custom_measures?.length ?? 0}`,
      {
        name: null,
        id: null,
        visible: false,
      }
    );
  };
//console.log("risk");
  const columns = [
    {
      key: 'name',
      title: 'Источник Наименование меры управления',
      align: 'center',
      render: (record) => record.name,
    },
    {
      key: 'group',
      title: 'Группа мер управления',
      align: 'center',
      width: 300,
      render: (record) => record.group,
    },
    {
      key: 'reliability_probability',
      title: 'Надежность меры управления',
      align: 'center',
      width: 260,
      render: (record) => (
        <Fields.AdditionalControls
          id={record.id}
          reliability_probability={record.reliability_probability}
        />
      ),
    },
    {
      key: 'remove',
      title: '',
      render: (record) => <RemoveButtonWithConfirm itemId={record.id} onClick={onRemoveItem} />,
    },
  ];

  const onHandleClose = () => {
    onRefetchTable();
    onClose();
  };

  return (
    <React.Fragment>
      <Portal id={'riskManagementMeasures'}>
        <Dialog
          isRender={isRender}
          isClose={true}
          title={'ПЕРЕЧЕНЬ ОБЩИХ МЕР УПРАВЛЕНИЯ РИСКАМИ В ОРГАНИЗАЦИИ'}
          onClose={onHandleClose}
          innerTop={'20px'}
          width={1400}
          settings={{
            title: {
              size: 14,
              fWeight: 600,
              lHeight: 17,
              align: 'left',
              margin: '20px -20px 17px',
            },
            close: {
              height: 20,
              width: 20,
              right: '8px',
              top: '8px',
            },
          }}
        >
          <Styled.ButtonSection variant={'primary'} onClick={handleAddDanger}>
            Добавить меру управления
          </Styled.ButtonSection>
          <Table
            rowKey={(record) => record.id}
            loading={loading}
            columns={columns}
            dataSource={dataRows}
            pagination={false}
          />
        </Dialog>
      </Portal>
      <Portal id={'addDangerSecond'}>
        <AddDanger
          project={project}
          title={'МЕРЫ УПРАВЛЕНИЯ'}
          rowId={rowId}
          workplace={workplace}
          formik={formikAddDanger}
          showAddDanger={showAddMeasures}
          setRowId={setRowId}
          onShowAddDanger={onShowAddMeasures}
          onPostEvaluationcardAddMeasuresProject={onPostEvaluationcardAddMeasuresProject}
          disabledWorkPlace={true}
          disabledWorkType={true}
        />
      </Portal>
    </React.Fragment>
  );
};
