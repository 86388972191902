import React, { useEffect } from 'react';
import { Button } from 'antd';

import * as hooks from '../../../../hooks';
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';

import Danger from './fields/danger';
import Emergency from './fields/emergency';
import Measures from './fields/measures';
import SourceDanger from './fields/sourceDanger'
import {Dangers, SourceDanger1, WorkPlace, WorkType } from './fields/ticket';
import TypeOfWork from './fields/typeOfWork';
import { usePostAddhazard } from './hooks';
import { ManagementMeasures } from './managementMeasures';
import * as Styled from './styled';

const { useBoolean } = hooks;
var work;

export default function AddDanger(props) {
  //let str;
  const {
    title,
    workplace,
    showAddDanger,
    onShowAddDanger,
    onPostProjectHazard,
    onPostProjectEvaluationcardHazard,
    onPostEvaluationcardAddMeasuresProject,
    formik,
    disabledWorkPlace = false,
    disabledWorkType = false,
    rowId,
    project,
    type,
    setRowId,
  } = props;

  const [showTypeOfWork, onShowTypeOfWork] = useBoolean(false);
  const [showEmergency, onShowEmergency] = useBoolean(false);
  const [showSourceDanger, onShowSourceDanger] = useBoolean(false);
  const [showDanger, onShowDanger] = useBoolean(false);
  const [showMeasures, onShowMeasures] = useBoolean(false);
  const [currentMeasures, setCurrentMeasures] = React.useState(null);
  //const [listDang, setListDang] = React.useState();

  const {
    onPostAddhazard,
    onPostDeleteSettlement,
    onPostSavehazard,
    onPostSaveProjecthazard,
    onPostAddProjecthazard,
    onPostReferenceBundle,
  } = usePostAddhazard({
    formik,
    onPostProjectHazard,
    onPostProjectEvaluationcardHazard,
    workplace,
    onShowAddDanger,
    project,
  });
  work = workplace;
  //const list = listDanger(workplace);
  const { values, status } = formik ?? {};
  //values.data.work_place.id = '5cefa6a4-4d4f-4232-8ab0-f2190c33be9e';
  //console.log('Start formik ', values);

  //console.log('www ',onPostFind(workplace));
//console.log(work);
  useEffect(() => {
    if (formik?.values?.data?.source_danger?.id || formik?.values?.data?.danger?.id) {
      const payload = {
        hazardId: rowId,
        workplaceId: workplace,
        projectId: project,
        sourceDangerId: formik?.values?.data?.source_danger?.id,
        dangerId: formik?.values?.data?.danger?.id,
        isCollective: formik.values.isCommonHazards,
      };

      onPostReferenceBundle(payload);
    }
  }, [
    rowId,
    formik.values?.data?.danger?.id,
    formik.values?.data?.source_danger?.id,
    workplace,
    project,
    formik.values.isCommonHazards,
  ]);


  //listDanger(workplace, setListDang);

  //let s;
  //let listVal = response.result;
  //console.log(listVal);
  //console.log(listDang);
  //console.log(formik.values.data);
  const titleDialog = status === 'addDanger' ? 'ДОБАВИТЬ ОПАСНОСТЬ' : 'РЕДАКТИРОВАТЬ ОПАСНОСТЬ';
  //const setV = (e) => {formik.setValues('work_place', e)}
  const handleClose = () => {
    //console.log('Close');

    //document.querySelectorAll('input').forEach(i => i.value = '')
    //console.log('Old', formik.values.data);
    formik.resetForm();
    //formik.resetForm();
    //formik.values.data.work_place = {work_place_id: "ttt", name: "jopa"};
    //console.log('New', formik.values.data);
    onShowAddDanger.off();
    if (setRowId) {
      setRowId(null);
    }
  };
  //listDanger(workplace);

  const onClickSave = () => {
    const { status } = formik ?? {};

    if (status === 'onlyMeasures') {
      return onPostEvaluationcardAddMeasuresProject();
    }

    if (status === 'addDanger' && type === 'generalHazardsOrganization') {
      return onPostAddProjecthazard();
    }

    if (type === 'generalHazardsOrganization') {
      return onPostSaveProjecthazard(rowId);
    }

    if (status === 'addDanger') {
      return onPostAddhazard();
    }

    if (rowId) {
      return onPostSavehazard(rowId);
    }

    return null;
  };
  //console.log('values',formik);

//const reb = ()=>{}
  if (status === 'onlyMeasures') {
    return (
      <Dialog
        isRender={showAddDanger}
        isClose={true}
        centered={false}
        onClose={handleClose}
        title={title || titleDialog}
        width={'700px'}
        paddingWrapper={'0 36px 0 0'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px',
          },
          close: {
            height: 20,
            width: 20,
            right: '8px',
            top: '8px',
          },
        }}
      >
        <div>
          <ManagementMeasures
            isHideActions={true}
            formik={formik}
            onPostDeleteSettlement={onPostDeleteSettlement}
            onCurrentMeasures={setCurrentMeasures}
            onShowMeasures={onShowMeasures}
          />
          <Styled.Buttons jContent={'flex-end'}>
            <Button
              type="primary"
              size="large"
              disabled={!formik.values.data?.custom_measures[0]?.id}
              onClick={onClickSave}
            >
              Сохранить
            </Button>
          </Styled.Buttons>
        </div>
        <Portal id={'measures'}>
          <Measures show={showMeasures} currentMeasures={currentMeasures} onShow={onShowMeasures} formik={formik} />
        </Portal>
      </Dialog>
    );
  }

  return (
    <Dialog
      isRender={showAddDanger}
      isClose={true}
      centered={false}
      onClose={handleClose}
      title={titleDialog}
      width={'700px'}
      paddingWrapper={'0 36px 0 0'}
      settings={{
        title: {
          size: 14,
          fWeight: 600,
          lHeight: 17,
          align: 'left',
          margin: '-2px -20px 40px',
        },
        close: {
          height: 20,
          width: 20,
          right: '8px',
          top: '8px',
        },
      }}
    >
      <div>
        <Styled.InputText
          onClick={() => {
            if (disabledWorkPlace) {
              return null;
            }

            return onShowTypeOfWork.on();
          }}
        >
          <Styled.InputTextLabel>Место выполнения работ</Styled.InputTextLabel>
          <Styled.InputTextInput disabled={disabledWorkPlace}>{values?.data?.work_place?.name}</Styled.InputTextInput>
          <div></div>
        </Styled.InputText>

            <WorkPlace workplace={work} formik={formik}  />

        <Styled.InputText
          onClick={() => {
            if (disabledWorkType) {
              return null;
            }

            onShowEmergency.on();
          }}
        >
          <Styled.InputTextLabel>Вид работ/НАС</Styled.InputTextLabel>
          <Styled.InputTextInput disabled={disabledWorkType}>{values?.data?.work_type?.name}</Styled.InputTextInput>
        </Styled.InputText>
            <WorkType workplace={work} formik={formik} />
        <Styled.InputText onClick={() => onShowSourceDanger.on()}>
          <Styled.InputTextLabel>Источник опасности</Styled.InputTextLabel>
          <Styled.InputTextInput>{values?.data?.source_danger?.name}</Styled.InputTextInput>
        </Styled.InputText>
            <SourceDanger1 workplace={work} formik={formik} />
        <Styled.InputText onClick={() => onShowDanger.on()}>
          <Styled.InputTextLabel>Опасность</Styled.InputTextLabel>
          <Styled.InputTextInput>{values?.data?.danger?.name}</Styled.InputTextInput>
        </Styled.InputText>
        <Dangers workplace={work} formik={formik} />
        <ManagementMeasures
          formik={formik}
          onPostDeleteSettlement={onPostDeleteSettlement}
          onCurrentMeasures={setCurrentMeasures}
          onShowMeasures={onShowMeasures}
        />
        <Styled.Buttons jContent={'flex-end'}>
          <Button type="primary" size="large" onClick={onClickSave}>
            Сохранить
          </Button>
        </Styled.Buttons>
      </div>
      <Portal id={'typeOfWork'}>
        <TypeOfWork show={showTypeOfWork} onShow={onShowTypeOfWork} formik={formik} />
      </Portal>
      <Portal id={'emergency'}>
        <Emergency show={showEmergency} onShow={onShowEmergency} formik={formik} />
      </Portal>
      <Portal id={'emergency'}>
        <SourceDanger show={showSourceDanger} onShow={onShowSourceDanger} formik={formik} />
      </Portal>
      <Portal id={'danger'}>
        <Danger show={showDanger} onShow={onShowDanger} formik={formik} />
      </Portal>
      <Portal id={'measures'}>
        <Measures show={showMeasures} currentMeasures={currentMeasures} onShow={onShowMeasures} formik={formik} />
      </Portal>
    </Dialog>
  );
}





